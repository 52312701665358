import React from 'react'
import { ifProp } from 'styled-tools'
import styled, { breakpoints, css, x } from '@xstyled/styled-components'
import { Col, Container, Row } from '@atoms/Grid'
import { th } from '@xstyled/system'
import Card from '@atoms/Card'
import { Text, TextLink } from '@atoms/Typography'
import CardCta from '@molecules/CardCta'
import Social from '@molecules/Social'
import { Link } from 'gatsby'

import PioneerLogo from '@assets/svg/pioneer-logo.svg'
import { menuItems } from './Header'

const Footer = ({ invertColor, customBackgroundColor }) => {
  const socialIcons = ['linkedin', 'twitter', 'github']
  return (
    <>
      <CardCta
        title="Ready to talk about your project?"
        button="Contact us"
        buttonLink="/contact/"
        invertColor={invertColor}
        customBackgroundColor={customBackgroundColor}
      />
      <S.Footer>
        <Container>
          <Row py={{ xs: 50, md: 130 }}>
            <Col
              col={{ xs: 1, md: 1 / 2 }}
              textAlign={{ xs: 'center', md: 'left' }}
              display="flex"
              flexDirection={{
                xs: 'column',
              }}
            >
              <Text mb={20}>
                <S.InfoLink
                  href="mailto:hello@crowdform.studio"
                  target="_blank"
                  noUnderline
                >
                  hello@crowdform.studio
                </S.InfoLink>
              </Text>
              <Text mb={{ xs: 50, md: 90 }} flex={{ xs: '1 1 auto' }}>
                <S.InfoLink href="tel:(+44) 208 050 1506" noUnderline>
                  (+44) 208 050 1506
                </S.InfoLink>
              </Text>

              <x.div
                row
                justifyContent="space-between"
                flex={{ xs: '0 1 auto' }}
                alignItems="center"
              >
                <S.CopyrightLinks>
                  <Text fontSize={{ xs: 12, md: 15 }}>
                    © Crowdform {new Date().getFullYear()}
                  </Text>
                  <TextLink internal to="/privacy-policy/" noUnderline>
                    Privacy
                  </TextLink>
                  <TextLink internal to="/legal/" noUnderline>
                    Legal
                  </TextLink>
                </S.CopyrightLinks>
                <Social display={{ md: 'none' }} icons={socialIcons} />
              </x.div>
            </Col>
            <Col
              col={{ xs: 1, md: 1 / 2 }}
              display={{ xs: 'none', md: 'block' }}
            >
              <x.div
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <S.WrapperLinks>
                  {menuItems.map(({ name, slug }) => (
                    <S.MenuFooterItem to={slug} key={slug} noUnderline>
                      {name}
                    </S.MenuFooterItem>
                  ))}
                </S.WrapperLinks>
                <x.div>
                  <Social display={{ md: 'block' }} icons={socialIcons} />
                  <x.a
                    as={Link}
                    to="https://www.p10neer.com/?rel=crowdform.studio"
                    target="_blank"
                    title="Pioneer Media Inc."
                    paddingTop="25px"
                    display="block"
                  >
                    <PioneerLogo />
                  </x.a>
                </x.div>
              </x.div>
            </Col>
          </Row>
        </Container>
      </S.Footer>
    </>
  )
}

export default Footer

const S = {}

S.Footer = styled.footerBox`
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
`

S.Cta = styled.box`
  background-color: ${ifProp('invertColor', 'transparent', th('colors.light'))}
    ${breakpoints({
      xs: css`
        padding: 100 0;
      `,
      md: css`
        padding: 110 0;
      `,
    })};
`

S.Card = styled(Card)`
  width: 100%;
  max-width: 480;
  margin: 0 auto;
  color: ${th('colors.dark')};
  text-align: center;
`

S.InfoLink = styled(TextLink).attrs(() => ({
  invertColor: true,
}))`
  border-color: transparent;
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    md: css`
      font-size: 20;
    `,
  })}
`

S.CopyrightLinks = styled.box`
  display: flex;
  ${breakpoints({
    xs: css`
      font-size: 12;
      > * {
        margin-right: 10;
      }
    `,
    md: css`
      font-size: 14;
      > * {
        margin-right: 30;
      }
    `,
  })}
  > p {
    white-space: nowrap;
  }
`

S.WrapperLinks = styled.box`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

S.MenuFooterItem = styled(TextLink).attrs(() => ({
  invertColor: true,
  internal: true,
}))`
  font-size: 20;
  margin-bottom: 16;
  border-color: transparent;
`
