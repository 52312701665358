import React from 'react'

import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { getSrc } from 'gatsby-plugin-image'

const CustomHelmet = ({ children, ...props }) => {
  const {
    // gatsby override
    title,
    description,
    author,
    date_published,
    date_modified,
    titleTemplate,
    content,
    meta, // SEO Markdown
    service,
    serviceSchemaData,
  } = props

  const location = useLocation()
  const article = content === 'Blog'

  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate: defaultTitleTemplate,
            defaultDescription,
            siteUrl,
            defaultImage,
            twitterUsername,
            siteLanguage,
            headline,
            author: siteAuthor,
            companyLogo,
          },
        },
      }) => {
        const socialShareCard = getSrc(meta?.image)

        const seo = {
          title: (meta && meta.title) || title || defaultTitle,
          description:
            description || (meta && meta.description) || defaultDescription,
          image:
            (socialShareCard && `${siteUrl + socialShareCard}`) ||
            (defaultImage && `${siteUrl + defaultImage}`),
          url: `${siteUrl}${location.pathname || '/'}`,
          author: (author && author.title) || siteAuthor,
          companyLogo: `${siteUrl}${companyLogo}`,
          titleTemplate: titleTemplate || defaultTitleTemplate,
        }

        // schema.org in JSONLD format
        // https://developers.google.com/search/docs/guides/intro-structured-data

        const schemaOrg = {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://www.crowdform.studio/',
          name: siteAuthor,
          logo: {
            '@type': 'ImageObject',
            url: seo.companyLogo,
          },
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+44 208 050 1506',
            contactType: 'Customer service',
          },
          address: {
            '@type': 'PostalAddress',
            streetAddress: '1-3 French Place',
            addressLocality: 'London',
            addressRegion: 'UK',
            postalCode: 'E1 6JB',
            addressCountry: 'GB',
          },
          sameAs: [
            'https://twitter.com/crowdformagency',
            'https://www.linkedin.com/company/crowdform',
            'https://github.com/crowdform',
            'https://www.instagram.com/crowdform/',
            'https://medium.com/@CrowdformLtd',
          ],
        }

        const schemaOrgWebPage = {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          url: siteUrl,
          headline,
          inLanguage: siteLanguage,
          mainEntityOfPage: siteUrl,
          description: defaultDescription,
          name: defaultTitle,
          copyrightHolder: {
            '@type': 'Organization',
            name: siteAuthor,
            logo: {
              '@type': 'ImageObject',
              url: seo.companyLogo,
            },
          },
          copyrightYear: '2021',
          creator: {
            '@type': 'Organization',
            name: siteAuthor,
            logo: {
              '@type': 'ImageObject',
              url: seo.companyLogo,
            },
          },
          publisher: {
            '@type': 'Organization',
            name: siteAuthor,
            logo: {
              '@type': 'ImageObject',
              url: seo.companyLogo,
            },
          },
          datePublished: date_published,
          dateModified: date_modified,
          image: {
            '@type': 'ImageObject',
            url: seo.image,
          },
        }

        // Initial breadcrumb list

        const itemListElement = [
          {
            '@type': 'ListItem',
            item: {
              '@id': siteUrl,
              name: 'Homepage',
            },
            position: 1,
          },
        ]

        let schemaArticle = null

        if (article) {
          schemaArticle = {
            '@context': 'http://schema.org',
            '@type': 'Article',
            author: {
              '@type': 'Person',
              name: seo.author,
            },
            copyrightHolder: {
              '@type': 'Organization',
              name: siteAuthor,
              logo: {
                '@type': 'ImageObject',
                url: seo.companyLogo,
              },
            },
            copyrightYear: '2021',
            creator: {
              '@type': 'Person',
              name: seo.author,
            },
            publisher: {
              '@type': 'Organization',
              name: siteAuthor,
              logo: {
                '@type': 'ImageObject',
                url: seo.companyLogo,
              },
            },
            datePublished: date_published,
            dateModified: date_modified,
            description: seo.description,
            headline: seo.title,
            inLanguage: siteLanguage,
            url: seo.url,
            name: seo.title,
            image: {
              '@type': 'ImageObject',
              url: seo.image,
            },
            mainEntityOfPage: seo.url,
          }
          // Push current blogpost into breadcrumb list
          itemListElement.push({
            '@type': 'ListItem',
            item: {
              '@id': `${siteUrl}/blog/`,
              name: 'Blog',
            },
            position: 2,
          })

          itemListElement.push({
            '@type': 'ListItem',
            item: {
              '@id': seo.url,
              name: seo.title,
            },
            position: 3,
          })
        }

        const breadcrumb = {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          description: 'Breadcrumbs list',
          name: 'Breadcrumbs',
          itemListElement,
        }

        let schemaService = null

        if (service) {
          schemaService = {
            '@id': seo.url,
            '@context': 'https://schema.org/',
            '@type': ['Product', 'Service'],
            url: seo.url,
            serviceType: serviceSchemaData.title,
            description: serviceSchemaData.description,
            provider: {
              '@type': 'Organization',
              name: siteAuthor,
            },
            areaServed: {
              '@type': 'City',
              name: 'London',
            },
            hasOfferCatalog: {
              '@type': 'OfferCatalog',
              name: serviceSchemaData.title,
              itemListElement: [],
            },
          }
          serviceSchemaData?.services.map((item) => {
            schemaService.hasOfferCatalog.itemListElement.push({
              '@type': 'OfferCatalog',
              name: item,
            })
          })
        }

        return (
          <Helmet title={seo.title}>
            <html lang="en" />
            <link rel="canonical" href={seo.url} />
            <link rel="alternate" href={seo.url} hreflang="en" />
            <link rel="alternate" href={seo.url} hreflang="x-default" />

            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#120f20"
            />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {/* Facebook */}
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="article" />

            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta property="og:site_name" content="Crowdform" />

            {content === 'Blog' && (
              <meta property="article:section" content="Blog" />
            )}

            {content === 'Blog' && (
              <meta
                property="article:date_published"
                content={date_published}
              />
            )}
            {content === 'Blog' && (
              <meta property="article:modified_time" content={date_modified} />
            )}
            {content === 'Blog' && (
              <meta property="og:date_modified" content={date_modified} />
            )}

            {seo.image && <meta property="og:image" content={seo.image} />}
            {seo.image && (
              <meta property="og:image:secure_url" content={seo.image} />
            )}

            {/* Twitter */}

            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            <script type="application/ld+json">
              {JSON.stringify(schemaOrg)}
            </script>
            {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
            {!article && (
              <script type="application/ld+json">
                {JSON.stringify(schemaOrgWebPage)}
              </script>
            )}
            {article && (
              <script article="true" type="application/ld+json">
                {JSON.stringify(schemaArticle)}
              </script>
            )}

            {service && (
              <script type="application/ld+json">
                {JSON.stringify(schemaService)}
              </script>
            )}

            <script type="application/ld+json">
              {JSON.stringify(breadcrumb)}
            </script>
            {children}
          </Helmet>
        )
      }}
    />
  )
}

CustomHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

CustomHelmet.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

export default CustomHelmet

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage
        twitterUsername
        siteLanguage
        author
        headline
        companyLogo
      }
    }
  }
`
