import React from 'react'
import { x } from '@xstyled/styled-components'
import useBreakpoint from '@hooks/useBreakpoint'
import { ButtonLink } from '@atoms/Button'
import { Text } from '@atoms/Typography'
import useRealTime from '@hooks/useRealTime'
import S from './style'

export default ({ items, open, onClose }) => {
  const { downTo } = useBreakpoint()
  const { london, saoPaulo } = useRealTime()

  return (
    <S.MenuWrapper open={open}>
      <x.div>
        <x.div row justifyContent="flex-end">
          <S.ButtonMenuMobile
            mr={[-10, -20]}
            variant={downTo('sm') ? 'dark' : 'yellow'}
            onClick={onClose}
          >
            <S.IconMenuClose />
          </S.ButtonMenuMobile>
        </x.div>

        <S.MenuModalWrapper my={50}>
          {items.map(({ name, slug }) => (
            <S.MenuModalItem to={slug} key={slug}>
              {name}
            </S.MenuModalItem>
          ))}
        </S.MenuModalWrapper>

        <x.div>
          <S.MenuModalText>Ready to talk about your project?</S.MenuModalText>
          <ButtonLink variant="dark" to="/contact/" mb="30px">
            Contact us
          </ButtonLink>
        </x.div>
      </x.div>

      <x.div row flex={0}>
        <x.div width={120} mr="50px">
          <Text fontSize={[14, 17]} fontWeight="black">
            London
          </Text>
          <Text fontSize={22}>{london}</Text>
        </x.div>
        <x.div width={120}>
          <Text fontSize={[14, 17]} fontWeight="black">
            São Paulo
          </Text>
          <Text fontSize={22}>{saoPaulo}</Text>
        </x.div>
      </x.div>
    </S.MenuWrapper>
  )
}
