import styled, { css, breakpoints } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import { variant } from '@utils/variant'
import { Link } from 'gatsby'
import { Text } from '@atoms/Typography'
import logoCF from '@assets/svg/crowdform.svg'
import hamburgerIcon from '@assets/svg/hamburger.svg'
import closeIcon from '@assets/svg/close.svg'

const S = {}

S.Header = styled.headerBox`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;

  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  ${breakpoints({
    xs: css`
      padding: 30px 0;
    `,
    md: css`
      padding: 55px 0;
    `,
  })}
`

S.Logo = styled(logoCF)`
  ${breakpoints({
    xs: css`
      height: 15;
      width: 110;
    `,
    md: css`
      height: 22;
      width: 161;
    `,
  })}
  display: block;
  transition: all 0.3s ease;
  ${variant({
    default: 'dark',
    variants: {
      dark: css`
        color: ${th('colors.dark')};
      `,
      light: css`
        color: ${th('colors.light')};
      `,
    },
  })}
`

S.HamburgerButton = styled.buttonBox`
  ${variant({
    default: 'light',
    variants: {
      dark: css`
        color: ${th('colors.dark')};
      `,
      light: css`
        color: ${th('colors.light')};
      `,
    },
  })}
`

S.Hamburger = styled(hamburgerIcon)`
  width: 25;
  height: 14;
  transition: color 0.3s ease;
  cursor: pointer;
`

S.WrapperMenu = styled.box`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

S.MenuItem = styled(Link)`
  font-size: 19;
  padding: 1 15;
  transition: color 0.3s ease;
  position: relative;
  ${variant({
    default: 'light',
    variants: {
      dark: css`
        color: ${th('colors.dark')};
      `,
      light: css`
        color: ${th('colors.light')};
      `,
    },
  })}
`

S.Bubble = styled.span`
  font-size: 14px;
  position: absolute;
  top: -15px;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: none;
  ${breakpoints({
    md: css`
      display: block;
    `,
  })}
`

S.ButtonMenuMobile = styled.buttonBox`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60;
  height: 60;
  border-radius: 50%;
  border: none;
  padding: 0;
  transition: all 0.3s ease;
  position: fixed;
  z-index: 1050;
  cursor: pointer;
  ${variant({
    default: 'dark',
    variants: {
      dark: css`
        background: ${th('colors.dark')};
        color: ${th('colors.light')};
      `,
      yellow: css`
        background: ${th('colors.yellow')};
        color: ${th('colors.dark')};
      `,
      light: css`
        background: ${th('colors.light')};
        color: ${th('colors.dark')};
      `,
    },
  })}
`

S.IconMenuMobile = styled(hamburgerIcon)`
  width: 14;
  height: 10;
`

S.IconMenuClose = styled(closeIcon)`
  width: 20;
  height: 20;
  transition: all 0.3s ease;
`

S.MenuWrapper = styled.box`
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? '0' : '-100%')};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${th('colors.yellow')};
  z-index: ${th('zIndices.menu')};
  transition: all 0.3s ease;
  overflow: scroll;
  ${breakpoints({
    xs: css`
      padding: 30 40;
      width: 100%;
    `,
    sm: css`
      padding: 40 60;
      max-width: 480;
    `,
  })}
`

S.MenuModalWrapper = styled.box`
  display: flex;
  flex-direction: column;
`

S.MenuModalItem = styled(Link)`
  font-weight: ${th('fontWeights.medium')};
  ${breakpoints({
    xs: css`
      font-size: 32;
    `,
    sm: css`
      font-size: 38;
    `,
  })}
`

S.MenuModalText = styled(Text)`
  max-width: 240;
  font-weight: ${th('fontWeights.medium')};
  margin-bottom: 15;
  ${breakpoints({
    xs: css`
      font-size: 18;
    `,
    sm: css`
      font-size: 20;
    `,
  })}
`

export default S
