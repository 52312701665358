import React from 'react'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import PropTypes from 'prop-types'

const Card = ({ shadow, ...props }) => <StyledCard shadow={shadow} {...props} />

Card.propTypes = {
  shadow: PropTypes.bool,
}

Card.defaultProps = {
  shadow: false,
}

const StyledCard = styled(({ shadow, ...props }) => <x.div {...props} />)`
  box-shadow: ${({ shadow }) =>
    shadow ? `0px 2px 3px ${th('colors.grey')}` : 'none'};
  background: ${th('colors.light')};
  border: 1px solid ${th('colors.grey')};

  ${breakpoints({
    xs: css`
      padding: 30;
      border-radius: 5;
    `,
    md: css`
      padding: 50;
      border-radius: 8;
    `,
  })}
`

export default Card
