import React from 'react'
import { ifProp } from 'styled-tools'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'
import { variant } from '@utils/variant'

import { th } from '@xstyled/system'
import { Text } from '@atoms/Typography'
import { ButtonLink, ButtonLinkExternal } from '@atoms/Button'

const CardCta = ({
  title,
  button,
  buttonLink,
  external,
  invertColor,
  customBackgroundColor,
}) => {
  return (
    <S.Wrapper invertColor={invertColor} variant={customBackgroundColor}>
      <S.Content>
        <S.Text
          fontSize={{ xs: 36, md: 42, xl: 72 }}
          fontWeight="medium"
          letterSpacing={{ xs: 0, md: -2 }}
          lineHeight={{ xs: '43px', md: '120%', xl: '110%' }}
          invert={
            invertColor && customBackgroundColor !== 'white' ? 'true' : 'false'
          }
          maxWidth={{ xs: '90%', md: '448px', xl: '700px' }}
          mb={{ xs: 40, md: 20, xl: 50 }}
          mx={{ xs: 'auto' }}
        >
          {title}
        </S.Text>

        {external ? (
          <S.ButtonLinkExternal variant="yellow">{button}</S.ButtonLinkExternal>
        ) : (
          <S.ButtonLink variant="yellow" to={buttonLink}>
            {button}
          </S.ButtonLink>
        )}
      </S.Content>
    </S.Wrapper>
  )
}

export default CardCta

const S = {}

S.Wrapper = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${ifProp(
    'invertColor',
    th('colors.dark'),
    th('colors.light')
  )};
  ${variant({
    variants: {
      white: css`
        color: ${th('colors.dark')};
        background-color: ${th('colors.light')};
      `,
      pink: css`
        background-color: ${th('colors.pink')};
      `,
      yellow: css`
        background-color: ${th('colors.yellow')};
      `,
      green: css`
        background-color: ${th('colors.green')};
      `,
      grey: css`
        background-color: ${th('colors.grey')};
      `,
      undefined: css``,
    },
  })}
  ${breakpoints({
    xs: css`
      padding: 54px 0 120px;
    `,
    md: css`
      padding: 54px 0 80px;
    `,
    xl: css`
      padding: 54px 0 ${ifProp('invertColor', '119px', '180px')};
    `,
  })}
`

S.Text = styled(Text)`
  color: ${(props) =>
    props.invert === 'true' ? th('colors.light') : th('colors.dark')};
`

S.Content = styled.box`
  margin: 0 auto;
  ${breakpoints({
    xs: css`
      max-width: 315;
    `,
    md: css`
      max-width: 660;
    `,
  })}
`

S.ButtonLinkExternal = styled(ButtonLinkExternal)`
  ${breakpoints({
    xs: css`
      width: 100%;
    `,
    md: css`
      width: unset;
      padding-left: 80;
      padding-right: 80;
    `,
  })}
`

S.ButtonLink = styled(ButtonLink)`
  ${breakpoints({
    xs: css`
      min-width: 255;
    `,
    md: css`
      width: unset;
      min-width: unset;
      padding-left: 40;
      padding-right: 40;
    `,
  })}
`
