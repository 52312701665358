import { useEffect, useState } from "react";

function useRealTime() {
  const [times, setTimes] = useState({
    saoPaulo: '00:00',
    london: '00:00',
  })

  useEffect(() => {
    const options = (timeZone) => ({
      hour: '2-digit',
      minute:'2-digit',
      hour12: false,
      timeZone
    })

    const getCurrentTime = () => {
      const currentDate = new Date();
      const saoPauloTime = currentDate.toLocaleString([], options('America/Sao_Paulo'))
      const londonTime = currentDate.toLocaleString([], options('Europe/London'))
      setTimes({
        saoPaulo: saoPauloTime,
        london: londonTime,
      })
    }
    getCurrentTime()

    const timer = setInterval(() => {
      getCurrentTime()
    }, 1000);
    
    return () => clearInterval(timer)
  }, []);

  return times
}

export default useRealTime
