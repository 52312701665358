import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { x } from '@xstyled/styled-components'
import { ButtonLink } from '@atoms/Button'
import { Link } from 'gatsby'

import { Container } from '@atoms/Grid'

import S from './style'
import MenuItems from './MenuItems'
import MenuModal from './MenuModal'

export const menuItems = [
  {
    name: 'Studio',
    slug: '/about/',
  },
  {
    name: 'Labs',
    slug: '/labs/',
  },
  {
    name: 'Projects',
    slug: '/work/',
  },
  {
    name: 'Thinking',
    slug: '/blog/',
  },
  {
    name: 'Careers',
    slug: '/careers/',
    bubbleValue: 'careersTotalCount',
  },
]

const Header = ({ invertColor, hamburger, forceButtonColor }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderCareersCount {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            collection: { eq: "careers" }
            status: { eq: "published" }
          }
        }
      ) {
        totalCount
      }
    }
  `)
  return (
    <S.Header>
      <Container>
        <x.div row justifyContent="space-between" alignItems="center">
          <x.div alignItems="center">
            <Link to="/" title="Crowdform | digital product studio">
              <S.Logo variant={invertColor ? 'light' : 'dark'} />
              <x.span display="none">Crowdform</x.span>
            </Link>
          </x.div>

          <x.div row as="nav" justifyContent="flex-end" alignItems="center">
            <S.HamburgerButton
              p="10px"
              cursor="pointer"
              onClick={() => setMenuOpen(true)}
              variant={invertColor ? 'light' : 'dark'}
              display={
                hamburger
                  ? { xs: 'none', md: 'block' }
                  : {
                      xs: 'none',
                      md: 'block',
                      lg: 'none',
                    }
              }
            >
              <S.Hamburger />
            </S.HamburgerButton>
            <MenuItems
              items={menuItems}
              invertColor={invertColor}
              careersTotalCount={data.allMarkdownRemark.totalCount}
              display={{
                xs: 'none',
                lg: hamburger ? 'none' : 'block',
              }}
            />

            <x.div ml={20} display={{ xs: 'none', md: 'block' }}>
              <ButtonLink
                large="true"
                to="/contact/"
                variant={forceButtonColor || 'light'}
              >
                Contact
              </ButtonLink>
            </x.div>

            <S.ButtonMenuMobile
              display={{ xs: 'flex', md: 'none' }}
              variant={forceButtonColor || 'light'}
              onClick={() => setMenuOpen(true)}
            >
              <S.IconMenuMobile />
            </S.ButtonMenuMobile>

            <MenuModal
              items={menuItems}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            />
          </x.div>
        </x.div>
      </Container>
    </S.Header>
  )
}

export default Header
