import React from 'react'
import S from './style'

export default ({ items, invertColor, display, ...props }) => (
  <S.WrapperMenu display={display}>
    {items.map(({ name, slug, bubbleValue }) => (
      <S.MenuItem variant={invertColor ? 'light' : 'dark'} to={slug} key={slug}>
        {name}
        {bubbleValue && <S.Bubble>{props[bubbleValue]}</S.Bubble>}
      </S.MenuItem>
    ))}
  </S.WrapperMenu>
)
