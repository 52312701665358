import React from 'react'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'
import idgen from '@utils/idgen'
import LinkedinIcon from '@assets/svg/linkedin.svg'
import TwitterIcon from '@assets/svg/twitter.svg'
import GithubIcon from '@assets/svg/github.svg'
import FacebookIcon from '@assets/svg/facebook.svg'
import MediumIcon from '@assets/svg/medium.svg'

const Social = ({ icons, display, ...props }) => {
  return (
    <x.div display={display}>
      {icons && (
        <x.div display="flex">
          {icons.map((icon) => (
            <React.Fragment key={idgen()}>
              {icon === 'linkedin' && (
                <S.SocialItem
                  target="_blank"
                  href="https://www.linkedin.com/company/crowdform"
                  {...props}
                >
                  <LinkedinIcon />
                </S.SocialItem>
              )}
              {icon === 'twitter' && (
                <S.SocialItem
                  target="_blank"
                  href="https://twitter.com/crowdformstudio"
                  {...props}
                >
                  <TwitterIcon />
                </S.SocialItem>
              )}
              {icon === 'github' && (
                <S.SocialItem
                  target="_blank"
                  href="https://github.com/crowdform"
                  {...props}
                >
                  <GithubIcon />
                </S.SocialItem>
              )}
              {icon === 'facebook' && (
                <S.SocialItem
                  target="_blank"
                  href="https://www.facebook.com/crowdform"
                  {...props}
                >
                  <FacebookIcon />
                </S.SocialItem>
              )}
              {icon === 'medium' && (
                <S.SocialItem
                  target="_blank"
                  href="https://medium.com/@CrowdformLtd'"
                  {...props}
                >
                  <MediumIcon />
                </S.SocialItem>
              )}
            </React.Fragment>
          ))}
        </x.div>
      )}
    </x.div>
  )
}

const S = {}

S.SocialItem = styled.aBox`
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  ${breakpoints({
    xs: css`
      margin-left: 15;
      svg {
        width: 16;
        height: 16;
      }
    `,
    md: css`
      margin-left: 20;
      svg {
        width: 18;
        height: 18;
      }
    `,
  })}
`

export default Social
