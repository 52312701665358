import React from 'react'
import styled, { x, breakpoints, css } from '@xstyled/styled-components'

export const containerStyle = css`
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${breakpoints({
    xs: css`
      padding-left: 30;
      padding-right: 30;
    `,
    md: css`
      padding-left: 15;
      padding-right: 15;
      max-width: 720px;
    `,
    lg: css`
      max-width: 890px;
      padding-left: 15;
      padding-right: 15;
    `,
    xl: css`
      max-width: 1280px;
      padding-left: 15;
      padding-right: 15;
    `,
  })}
`

export const Container = styled.box`
  ${containerStyle}
`

export const Section = styled.sectionBox`
  ${containerStyle}
`

export const Row = (props) => <x.div row mx={{ xs: -30, md: -15 }} {...props} />
Row.defaultProps = {
  row: true,
}

export const Col = ({ col, ...props }) => (
  <x.div col={col} px={{ xs: 30, md: 15 }} {...props} />
)
Col.defaultProps = {
  col: true,
}
