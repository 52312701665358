import { useEffect, useState } from 'react'
import { useScreens } from '@xstyled/styled-components'

function useBreakpoint() {
  const breakpoints = useScreens()

  const [windowSize, setWindowSize] = useState(0)
  const [htmlFontSize, setHtmlFontSize] = useState(0)

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth
      const defaultFontSize = window
        .getComputedStyle(document.documentElement)
        .fontSize.replace('px', '')
      setWindowSize(width)
      setHtmlFontSize(Number(defaultFontSize))
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const upTo = (bp) => {
    return windowSize >= Number(breakpoints[bp]) * htmlFontSize
  }

  const downTo = (bp) => {
    return windowSize < Number(breakpoints[bp]) * htmlFontSize
  }

  return {
    upTo,
    downTo,
  }
}

export default useBreakpoint
