import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { variant } from '@utils/variant'
import { margin, th, space } from '@xstyled/system'

import { Link } from 'gatsby'
import { ifProp } from 'styled-tools'

import arrowRight from '@assets/svg/arrow-right.svg'

const Button = (props) => (
  <ButtonStyled large {...props}>
    <span>{props.children}</span>
    {props.arrowRight && <ArrowRightIcon />}
  </ButtonStyled>
)
const ButtonLink = (props) => {
  let obj = {}
  if (props.external) {
    obj.as = 'a'
    obj.href = props.to
  }
  if (props.to?.includes('mailto:')) {
    obj.as = 'a'
    obj.href = props.to
    obj.target = '_blank'
  }
  return (
    <ButtonLinkStyled {...props} {...obj}>
      <span>{props.children}</span>
      {props.arrowRight && <ArrowRightIcon />}
    </ButtonLinkStyled>
  )
}

const ArrowRightIcon = styled(arrowRight)`
  width: auto;
  height: 16;
  margin-left: 5px;

  path {
    fill: var(--svg-path-color, ${th('colors.dark')});
  }
`

const buttonStyle = css`
  height: ${ifProp('large', '50px', '48px')};
  padding: 0 30;
  font-size: ${ifProp('large', '19px', '17px')};
  font-weight: ${th('fontWeights.medium')};
  border-radius: 25;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.1;
  transition: all 0.3s ease;
  ${margin}
  ${ifProp(
    'arrowRight',
    css`
      padding: 0 20;
    `
  )}
  ${variant({
    default: 'pink',
    variants: {
      pink: css`
        background-color: ${th('colors.pink')};
        color: ${th('colors.dark')};
        &:hover {
          background-color: ${th('colors.dark')};
          color: ${th('colors.pink')};
        }
      `,
      yellow: css`
        background-color: ${th('colors.yellow')};
        color: ${th('colors.dark')};
        &:hover {
          background-color: ${th('colors.dark')};
          color: ${th('colors.yellow')};
        }
      `,
      green: css`
        background-color: ${th('colors.green')};
        color: ${th('colors.dark')};
        &:hover {
          background-color: ${th('colors.dark')};
          color: ${th('colors.green')};
          --svg-path-color: ${th('colors.green')};
        }
      `,
      grey900: css`
        background-color: ${th('colors.grey900')};
        color: ${th('colors.white')};
        --svg-path-color: ${th('colors.white')};

        &:hover {
          background-color: ${th('colors.white')};
          color: ${th('colors.grey900')};
          --svg-path-color: ${th('colors.grey900')};
        }
      `,
      dark: css`
        background-color: ${th('colors.dark')};
        color: ${th('colors.light')};
        --svg-path-color: ${th('colors.light')};
        &:hover {
          --svg-path-color: ${th('colors.dark')};
          background-color: ${th('colors.light')};
          color: ${th('colors.dark')};
        }
      `,
      light: css`
        background-color: ${th('colors.light')};
        color: ${th('colors.dark')};
        &:hover {
          background-color: ${th('colors.dark')};
          color: ${th('colors.light')};
        },
      `,
      white: css`
        background-color: ${th('colors.white')};
        color: ${th('colors.dark')};
        &:hover {
          background-color: ${th('colors.dark')};
          color: ${th('colors.white')};
          --svg-path-color: ${th('colors.white')};
        }
      `,
    },
  })}
`

const ButtonStyled = styled.buttonBox`
  ${buttonStyle}
  ${space}
`

const ButtonLinkStyled = styled(Link)`
  ${buttonStyle}
  ${space}
`

export const ButtonLinkExternal = styled.aBox`
  ${buttonStyle}
`

export { Button, ButtonLink }
