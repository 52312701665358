import React, { useEffect } from 'react'
import { x } from '@xstyled/styled-components'
import Header from '@organisms/Header/index'
import Footer from '@organisms/Footer'
import Helmet from '@atoms/Helmet'

import initNetlify from '@utils/netlifyCms'

import { preloadFonts } from '@assets/styles'

const Layout = ({
  children,
  invertColor,
  hamburgerMenu,
  buttonColor,
  customBackgroundColor,
  meta,
  ...props
}) => {
  useEffect(() => {
    initNetlify()
  }, [])
  return (
    <x.div>
      <Helmet {...props} meta={meta}>
        {preloadFonts.map((font, i) => {
          return (
            <link
              key={i}
              rel="preload"
              href={font}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          )
        })}
      </Helmet>
      <Header
        invertColor={invertColor}
        hamburger={hamburgerMenu}
        forceButtonColor={buttonColor}
      />
      {children}
      <Footer
        invertColor={invertColor}
        customBackgroundColor={customBackgroundColor}
      />
    </x.div>
  )
}

export default Layout
